<template>
  <div>
    <el-row>
      <!-- 右侧，周一到周五具体内容 -->
      <el-col :span="24">
        <!-- 第一行表头，周一到周日 -->
        <div class="top-con">
          <div class="top" v-for="item in top" :key="item">{{ item }}</div>
        </div>
        <!-- 日历号 -->
        <div class="date-con">
          <div
            class="date"
            :class="[item.thisMonth, item.isToday, item.afterToday]"
            v-for="(item, index) in visibleCalendar"
            :key="index"
            @click="dateClick(item)"
          >
            <div class="calendarContent">
              <div class="calendarContentDay" v-if="item.isToday && item.thisMonth">
                <div
                  :style="
                    item.calendarNum.length > 0 ? 'width: 60%; text-align: right' : 'width: 100%'
                  "
                >
                  {{ item.isToday ? '✅' + '今天' : '' }}
                </div>
                <div class="calendarContentListHidden" v-if="item.calendarNum.length > 0">
                  <span>共{{ item.calendarNum.length }}人</span>
                </div>
              </div>
              <div v-else-if="item.thisMonth" class="calendarContentDay">
                <div
                  :style="
                    item.calendarNum.length !== 0 ? 'width: 55%; text-align: right' : 'width: 100%'
                  "
                >
                  {{ item.day }}
                </div>
                <div class="calendarContentListHidden" v-if="item.calendarNum.length > 0">
                  <span>共{{ item.calendarNum.length }}人</span>
                </div>
              </div>
              <div v-if="item.thisMonth" class="calendarContentList">
                <div class="calendarInfo">
                  <div
                    class="calendarItem"
                    v-for="(followItem, followIndex) in item.calendarNum"
                    :key="'follow' + followIndex + index + item.day"
                  >
                    <template>
                      <div class="calendarItemInfo">
                        <span v-if="followItem.followStatus === '已完成'">✔️</span>
                        <span v-else>
                          <i class="el-icon-time titleNoteItemIcon"></i>
                        </span>
                        <span style="font-weight: bold">{{ followIndex + 1 }}、</span>
                        {{ followItem.fileNumber ? followItem.fileNumber + '/' : '' }}
                        {{ followItem.patientName ? followItem.patientName + '/' : '' }}
                        {{ followItem.patientID ? followItem.patientID + '/' : '' }}
                        {{ followItem.moduleAlias || followItem.moduleName }}/{{
                          followItem.followTitle
                        }}/{{ followItem.confirmOrderDate?.substr(11, 5) }}
                        {{ followItem.memo ? '(' + followItem.memo + ')' : '' }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Object,
      default: () => {
        return {}
      }
    },
    calendarList: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tabIndex: {
      type: String,
      default: () => {
        return ''
      }
    },
    calendarThis: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  components: {},
  data() {
    return {
      top: ['一', '二', '三', '四', '五', '六', '日'],
      clickDay: ''
    }
  },
  watch: {
    tabIndex: {
      handler(val) {
        if (val) {
          this.tabIndex = val
        }
      },
      immediate: true
    },
    calendarList: {
      handler(val) {
        if (val) {
          this.calendarList = val
        }
      },
      immediate: true
    },
    calendarThis: {
      handler(val) {
        if (val) {
          this.calendarThis = val
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取
    dateClick(item) {
      if (this.tabIndex == '2') {
        if (item.thisMonth) {
          this.clickDay =
            item.year +
            '-' +
            (item.month + 1 < 10 ? '0' + (item.month + 1) : item.month + 1) +
            '-' +
            (item.day < 10 ? '0' + item.day : item.day)
          this.$emit('drawerClose', this.clickDay, this.tabIndex)
        }
      }
    },
    calendarInfoClose(index, e) {
      this.visibleCalendar[index].isShow = true
      this.$forceUpdate()

      e = e || window.event
      if (e.stopPropagation) {
        e.stopPropagation() //W3C阻止冒泡方法
        return false
      } else {
        e.cancelBubble = true //IE阻止冒泡方法
        return false
      }
    }
  },
  computed: {
    // 获取当前月份显示日历,共42天
    visibleCalendar: function () {
      // 获取今天的年月日
      const today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      const calendarArr = []
      // 获取当前月份第一天
      const currentFirstDay = new Date(this.time.year, this.time.month, 1)
      // 获取第一天是周几，注意周日的时候getDay()返回的是0，要做特殊处理
      const weekDay = currentFirstDay.getDay() === 0 ? 7 : currentFirstDay.getDay()
      // 用当前月份第一天减去周几前面几天，就是看见的日历的第一天
      const startDay = currentFirstDay - (weekDay - 1) * 24 * 3600 * 1000
      // 统一用42天来显示当前显示日历
      for (let i = 0; i < 42; i++) {
        const date = new Date(startDay + i * 24 * 3600 * 1000)
        calendarArr.push({
          date: new Date(startDay + i * 24 * 3600 * 1000),
          year: date.getFullYear(),
          month: date.getMonth(),
          day: date.getDate(),
          // 是否在当月
          thisMonth:
            date.getFullYear() === currentFirstDay.getFullYear() &&
            date.getMonth() === currentFirstDay.getMonth()
              ? 'thisMonth'
              : '',
          // 是否是今天
          isToday:
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()
              ? 'isToday'
              : '',
          calendarNum: {},
          isShow: true,
          // 是否在今天之后
          afterToday: date.getTime() >= today.getTime() ? 'afterToday' : ''
        })
        for (let j in this.calendarList) {
          if (calendarArr[i].thisMonth) {
            if (calendarArr[i].day == j) {
              calendarArr[i].calendarNum = this.calendarList[j]
            }
          }
        }
      }
      return calendarArr
    }
  }
}
</script>
<style lang="less" scoped>
.top-con {
  display: flex;
  align-items: center;
  .top {
    width: 14.285%;
    background-color: #bdaead;
    color: #856363;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.8px;
    padding: 10px 0;
    margin: 5px;
    text-align: center;
  }
}
/deep/ .el-popover {
  min-width: 80px !important;
}
.calendarContentList::-webkit-scrollbar {
  width: 10px;
}
.calendarContentList::-webkit-scrollbar-track {
  border-radius: 10px;
}
.calendarContentList::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.calendarContentList::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.date-con {
  display: flex;
  flex-wrap: wrap;
  .thisMonth:hover {
    cursor: pointer;
    background: #f1f1f1;
  }
  .date {
    width: 14.285%;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
  }
  .thisMonth {
    font-weight: 600;
    font-size: 16px;
    .calendarContent {
      height: 300px;
      display: flex;
      flex-direction: column;
      border: 1px #eee solid;
      box-sizing: border-box;
      position: relative;
      .calendarContentDay {
        height: 24px;
        display: flex;
        align-items: center;
      }
      .calendarContentList {
        flex: 1;
        overflow: auto;
      }
      .calendarInfo {
        font-weight: 400;
        display: flex;
        flex-direction: column;
        // background-color: rgba(220, 245, 253, 0.5);
        span {
          font-weight: bold;
        }
        .calendarItem {
          display: flex;
          align-items: center;
          padding-left: 10px;
          .calendarItemInfo {
            width: 100%;
            margin-bottom: 8px;
            text-align: left;
            .titleNoteItemIcon {
              color: #00ad1d;
              font-size: 20px;
              padding: 0 3px;
            }
          }

          .calendarItemIcon {
            font-size: 16px;
            margin-bottom: 8px;
          }
        }
      }

      .calendarContentListHidden {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
        span {
          color: rgb(117, 117, 117);
          font-size: 14px;
        }
      }
    }
  }
  .isToday {
    font-weight: 700;
  }
}
</style>
