<style scoped lang="less">
.button-panel {
  width: 100%;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
.top-item-row {
  margin: 20px 80px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  margin: 0.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-row-lang {
  margin: 0.5rem 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-row > span {
  width: 120px;
  margin: 5px 0;
}
.input-form-item-content {
  width: 180px;
  .el-date-editor.el-input {
    width: 180px;
  }
}
.input-form-item-menuContent {
  display: flex;
  align-items: center;
  .followCheckbox {
    width: 15%;
    padding: 0 10px;
    height: 25px;
    line-height: 25px;
  }
}
.addBtn {
  padding: 4px;
  margin-left: 7px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="随访预约登记" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>随访阶段:</span>
        <div class="input-form-item-content">
          <!-- <el-select v-model="templateInfo.followTitle" placeholder="请选择" :disabled="true">
            <el-option
              v-for="(item, index) in followMenuList"
              :key="index"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select> -->
          <el-input
            v-model="templateInfo.followTitle"
            :disabled="true"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>确认随访时间:</span>
        <div class="input-form-item-content">
          <el-date-picker
            v-model="templateInfo.confirmOrderDate"
            type="datetime"
            size="mini"
            align="center"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm"
            format="yyyy-MM-dd HH:mm"
          ></el-date-picker>
        </div>
      </div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>备注:</span>
        <div class="input-form-item-content">
          <el-input
            type="textarea"
            v-model="templateInfo.memo"
            size="mini"
            :rows="3"
            placeholder="请输入"
          ></el-input>
        </div>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import deepClone from '../../../plugins/clone'

export default {
  name: 'followAppointmentDialog',
  components: {
    CmDialogBorder
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      templateInfo: {}
    }
  },
  methods: {
    Show(bloodVessel) {
      if (bloodVessel) {
        this.templateInfo = deepClone(bloodVessel)
        if (bloodVessel.confirmOrderDate) {
          this.templateInfo.confirmOrderDate = deepClone(bloodVessel.confirmOrderDate)
        } else {
          this.templateInfo.confirmOrderDate = deepClone(bloodVessel.planFollowDate)
        }
      }
      this.isShow = true
    },
    Save() {
      this.$emit('save', this.templateInfo)
      this.templateInfo = {}
      this.isShow = false
    },

    Close() {
      this.templateInfo = {}
      this.isShow = false
    }
  }
}
</script>
