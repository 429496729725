<template>
  <div class="research-home">
    <IndexHeader />
    <div class="research-home-content">
      <el-drawer
        :visible.sync="notificationDrawer"
        direction="rtl"
        size="75%"
        :before-close="notificationHandleClose"
      >
        <template slot="title">
          <div class="research-home-title">
            <i class="el-icon-phone-outline" style="font-size: 18px" />
            <span>随访通知</span>
            <span class="drawerRight" v-if="tabs_index === '1' && this.notificationDrawer === true">
              总计: {{ this.userInfo_notDated.total }}条
            </span>
          </div>
        </template>
        <div>
          <div class="surgery-show-drawer">
            <div class="op-wrap">
              <el-button
                type="primary"
                size="mini"
                class="commonBtn"
                v-debounce="followSearch"
                icon="el-icon-search"
              >
                查询
              </el-button>
              <el-button
                type="primary"
                size="mini"
                class="commonBtn"
                v-debounce="clearTable"
                icon="el-icon-refresh-left"
              >
                清空
              </el-button>
            </div>

            <el-card class="conditions-wrap-two">
              <el-row class="item-wrap" type="flex" align="middle" justify="space-around">
                <el-col :span="isNewCoL === false ? 8 : 12">
                  <el-row type="flex" align="middle">
                    <el-col :span="6" class="label">通知时间</el-col>
                    <el-col :span="16">
                      <el-date-picker
                        v-model="noticeDate"
                        type="daterange"
                        align="center"
                        size="mini"
                        unlink-panels
                        @change="handleNoticeDate"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="isNewCoL === false ? 7 : 5">
                  <el-row type="flex" align="middle">
                    <el-col :span="6" class="label">库号</el-col>
                    <el-col :span="isNewCoL === false ? 8 : 12">
                      <el-input
                        placeholder="请输入患者库号"
                        clearable
                        type="string"
                        v-model="listQuery.fileNumber"
                        @change="fetchData(1)"
                        size="mini"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-col>
                <el-col :span="isNewCoL === false ? 7 : 5">
                  <el-row type="flex" align="middle">
                    <el-col :span="6" class="label">患者姓名</el-col>
                    <el-col :span="isNewCoL === false ? 12 : 16">
                      <el-input
                        placeholder="请输入患者姓名"
                        type="string"
                        clearable
                        v-model="listQuery.patientName"
                        @change="fetchData(1)"
                        size="mini"
                      ></el-input>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
            <el-card class="surgery-show-content">
              <el-table
                v-loading="tableLoading"
                :data="notDatedTableData"
                border
                style="width: 100%; border: none"
                :height="tableHeight + 20"
                @selection-change="handleSelectionChange"
              >
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column
                  align="center"
                  prop="fileNumber"
                  label="库号"
                  width="150"
                ></el-table-column>
                <el-table-column align="center" label="患者姓名" sortable width="100">
                  <template slot-scope="scope">
                    <div class="patientNameContent">
                      <el-button @click="checkPatient(scope.row)" type="text" size="small">
                        {{ scope.row.patientName }}
                      </el-button>
                      <div v-if="scope.row.isDead == '是'" class="patientIsDead">亡</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientID"
                  label="病案号"
                  width="130"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientSex"
                  label="性别"
                  width="55"
                ></el-table-column>
                <el-table-column
                  align="center"
                  sortable
                  label="年龄"
                  show-overflow-tooltip
                  prop="patientsAge"
                  width="70"
                >
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="联系电话" show-overflow-tooltip width="130">
                  <template slot-scope="scope">
                    <span v-if="scope.row.patientPhone">{{ scope.row.patientPhone }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="手术名称" show-overflow-tooltip width="200">
                  <template slot-scope="scope">
                    <span v-if="scope.row.examineNames">{{ scope.row.examineNames }}</span>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="手术时间" width="120">
                  <template slot-scope="scope">
                    <div class="patientNameContent">
                      {{ formatDate(scope.row.studyDate) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" label="本次随访" min-width="160">
                  <template slot-scope="scope">
                    <div class="patientNameContent">
                      <el-tag type="info" size="small">{{ scope.row.followTitle }}</el-tag>
                      <el-tag type="info" size="small" style="margin-left: 5px">
                        {{ formatDate(scope.row.planFollowDate) }}
                        {{ scope.row.morningOrAfternoon }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="lastNoticeDate"
                  label="上次通知时间"
                  sortable
                  width="160"
                >
                  <template slot-scope="scope">
                    {{ dateFormat(scope.row.lastNoticeDate) }}
                  </template>
                </el-table-column>
                <!-- <el-table-column
                    align="center"
                    prop="noticeMethod"
                    label="通知方式"
                    width="120"
                  ></el-table-column> -->
                <el-table-column
                  align="center"
                  prop="noticeCount"
                  sortable
                  label="通知次数"
                  width="100"
                ></el-table-column>
                <!-- <el-table-column align="center" prop="message" label="通知内容" width="160">
                    <template slot-scope="scope">
                      <el-popover trigger="hover" placement="bottom" width="400">
                        <span>{{ scope.row.message }}</span>
                        <div slot="reference" class="name-wrapper">
                          <span style="cursor: default">{{ scope.row.message }}</span>
                        </div>
                      </el-popover>
                    </template>
                  </el-table-column> -->

                <el-table-column align="center" prop="followStatus" width="120" label="随访状态">
                  <template slot-scope="scope">
                    <div v-if="scope.row.followStatus == '未预约'">
                      <el-tag type="info" size="small" v-show="scope.row.followStatus">
                        {{ scope.row.followStatus }}
                      </el-tag>
                    </div>

                    <div v-if="scope.row.followStatus == '待随访'">
                      <el-tag size="small" v-show="scope.row.followStatus">
                        {{ scope.row.followStatus }}
                      </el-tag>
                    </div>

                    <div v-if="scope.row.followStatus == '已完成'">
                      <el-tag type="success" size="small" v-show="scope.row.followStatus">
                        {{ scope.row.followStatus }}
                      </el-tag>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="userInfo_notDated.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_notDated.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_notDated.total"
              ></el-pagination>
            </el-card>
          </div>
        </div>
      </el-drawer>
      <el-drawer
        :visible.sync="waitFollowDrawer"
        direction="rtl"
        size="75%"
        :before-close="waitFollowHandleClose"
      >
        <template slot="title">
          <div class="research-home-title">
            <i class="el-icon-reading" style="font-size: 18px" />
            <span>{{ waitFollowTitle }} 当天随访人数详情</span>
            <span class="drawerRight" v-if="tabs_index === '2' && this.waitFollowDrawer === true">
              总计: {{ this.userInfo_drawerFollow.total }}条
            </span>
          </div>
        </template>

        <div class="surgery-show-drawer">
          <div class="op-wrap">
            <el-button
              type="primary"
              size="mini"
              class="commonBtn"
              v-debounce="getCalendarList"
              icon="el-icon-search"
            >
              查询
            </el-button>
            <el-button
              type="primary"
              size="mini"
              class="commonBtn"
              v-debounce="clearTable"
              icon="el-icon-refresh-left"
            >
              清空
            </el-button>
          </div>

          <el-card class="conditions-wrap-two">
            <el-row class="item-wrap" type="flex" align="middle" justify="space-around">
              <el-col :span="isNewCoL === false ? 6 : 10">
                <el-row type="flex" align="middle">
                  <el-col :span="6" class="label">随访时间</el-col>
                  <el-col :span="16">
                    <el-date-picker
                      v-model="searchDay"
                      type="date"
                      align="center"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      @change="getCalendarList"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row type="flex" align="middle">
                  <el-col :span="8" class="label">患者姓名</el-col>
                  <el-col :span="16">
                    <el-input
                      placeholder="请输入患者姓名"
                      type="string"
                      clearable
                      v-model="listQuery.patientName"
                      @change="getCalendarList"
                      size="mini"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="6">
                <el-row type="flex" align="middle">
                  <el-col :span="8" class="label">库号</el-col>
                  <el-col :span="16">
                    <el-input
                      placeholder="请输入患者姓名"
                      type="string"
                      clearable
                      v-model="listQuery.fileNumber"
                      @change="getCalendarList"
                      size="mini"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="surgery-show-content">
            <el-table
              v-loading="tableLoading"
              :data="drawerFollowTableData"
              border
              style="width: 100%; border: none"
              :height="tableHeight + 15"
              @selection-change="handleSelectionChange"
            >
              <el-table-column align="center" type="index" width="30"></el-table-column>

              <el-table-column
                align="center"
                prop="fileNumber"
                label="库号"
                width="150"
              ></el-table-column>
              <el-table-column align="center" label="患者姓名" sortable width="100">
                <template slot-scope="scope">
                  <div class="patientNameContent">
                    <el-button @click="checkPatientFollow(scope.row)" type="text" size="small">
                      {{ scope.row.patientName }}
                    </el-button>
                    <div v-if="scope.row.isDead == '是'" class="patientIsDead">亡</div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="patientID"
                label="病案号"
                width="130"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="patientSex"
                label="性别"
                width="55"
              ></el-table-column>
              <el-table-column
                align="center"
                sortable
                label="年龄"
                show-overflow-tooltip
                prop="patientsAge"
                width="70"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.ageDays">
                    {{ calculateAgeFromDays(scope.row.ageDays) }}
                  </span>
                  <span v-else>
                    {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="联系电话" show-overflow-tooltip width="130">
                <template slot-scope="scope">
                  <span v-if="scope.row.patientPhone">{{ scope.row.patientPhone }}</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="studyDate"
                label="手术时间"
                sortable
                width="120"
              >
                <template slot-scope="scope">
                  <div class="patientNameContent">
                    {{ formatHoursDate(scope.row.studyDate, 16) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="followTitle"
                label="随访阶段"
                sortable
                width="120"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="confirmOrderDate"
                label="预约时间"
                sortable
                width="170"
              >
                <template slot-scope="scope">
                  <div class="patientNameContent">
                    {{ formatHoursDate(scope.row.confirmOrderDate, 16) }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                align="center"
                prop="memo"
                label="备注"
                show-overflow-tooltip
                min-width="200"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.memo">{{ scope.row.memo }}</span>
                </template>
              </el-table-column>

              <el-table-column align="center" prop="status" width="100" label="随访状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.followStatus == '未预约'">
                    <el-tag type="info" size="small" v-show="scope.row.followStatus">
                      {{ scope.row.followStatus }}
                    </el-tag>
                  </div>

                  <div v-if="scope.row.followStatus == '待随访'">
                    <el-tag size="small" v-show="scope.row.followStatus">
                      {{ scope.row.followStatus }}
                    </el-tag>
                  </div>

                  <div v-if="scope.row.followStatus == '已完成'">
                    <el-tag type="success" size="small" v-show="scope.row.followStatus">
                      {{ scope.row.followStatus }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="100" label="操作">
                <template slot-scope="scope">
                  <div v-if="scope.row.followStatus == '待随访'">
                    <el-button type="primary" size="mini" @click="cencelFollow(scope.row.followId)">
                      取消预约
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="handleSizeChange5"
              @current-change="handleCurrentChange5"
              :current-page="userInfo_drawerFollow.pagenum"
              :page-sizes="[15, 30, 50]"
              :page-size="userInfo_drawerFollow.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="userInfo_drawerFollow.total"
            ></el-pagination>
          </el-card>
        </div>
      </el-drawer>
      <div class="surgery-status">
        <el-tabs tab-position="left" v-model="tabs_index" @tab-click="handleClick">
          <el-tab-pane name="0">
            <span slot="label">
              <i class="el-icon-document" style="padding-right: 5px"></i>
              数据填报
            </span>
            <div class="surgery-show">
              <div class="op-wrap">
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  v-debounce="followSearch"
                  icon="el-icon-search"
                >
                  查询
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  v-debounce="clearTable"
                  icon="el-icon-refresh-left"
                >
                  清空
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  v-debounce="batchAllocation"
                  icon="el-icon-finished"
                >
                  下载名单
                </el-button>
                <!-- <el-button
                  type="primary"
                  size="mini"
                  v-debounce="exportList"
                  icon="el-icon-download"
                >
                  导出随访信息
                </el-button> -->
              </div>

              <el-card class="conditions-wrap-two">
                <el-row class="item-wrap" type="flex" align="middle" justify="space-around">
                  <el-col :span="isNewCoL === false ? 6 : 10">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">库号</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 16">
                        <el-input
                          placeholder="请输入患者库号"
                          clearable
                          type="string"
                          v-model="listQuery.fileNumber"
                          @change="fetchData(1)"
                          size="mini"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">患者姓名</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 16">
                        <el-input
                          placeholder="请输入患者姓名"
                          type="string"
                          clearable
                          v-model="listQuery.patientName"
                          @change="fetchData(1)"
                          size="mini"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">病案号</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 16">
                        <el-input
                          placeholder="请输入病案号"
                          type="string"
                          clearable
                          v-model="listQuery.patientID"
                          @change="fetchData(1)"
                          size="mini"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row class="item-wrap" type="flex" align="middle" justify="space-around">
                  <el-col :span="isNewCoL === false ? 6 : 10">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">手术时间</el-col>
                      <el-col :span="16">
                        <el-date-picker
                          v-model="operationDate"
                          type="daterange"
                          align="center"
                          size="mini"
                          unlink-panels
                          @change="handleOperationDate"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6"></el-col>
                  <el-col :span="6"></el-col>
                </el-row>
              </el-card>
              <el-card class="surgery-show-content">
                <el-table
                  v-loading="tableLoading"
                  :data="tableAllData"
                  border
                  style="width: 100%"
                  :height="tableHeight + 35"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column align="center" type="index" width="30"></el-table-column>
                  <el-table-column
                    type="selection"
                    :selectable="handleDisable"
                    width="70"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="fileNumber"
                    label="库号"
                    show-overflow-tooltip
                    width="130"
                  ></el-table-column>
                  <el-table-column align="center" label="患者姓名" sortable width="100">
                    <template slot-scope="scope">
                      <div class="patientNameContent">
                        <el-button @click="checkPatient(scope.row)" type="text" size="small">
                          {{ scope.row.patientsName }}
                        </el-button>
                        <div v-if="scope.row.isDead == '是'" class="patientIsDead">亡</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="patientsID"
                    label="病案号"
                    width="130"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="patientsSex"
                    label="性别"
                    width="60"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    sortable
                    label="年龄"
                    show-overflow-tooltip
                    prop="patientsAge"
                    width="70"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.ageDays">
                        {{ calculateAgeFromDays(scope.row.ageDays) }}
                      </span>
                      <span v-else>
                        {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="联系电话"
                    show-overflow-tooltip
                    width="150"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.patientsPhone">{{ scope.row.patientsPhone }}</span>
                      <span v-else>{{ scope.row.contactPhone }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="手术名称"
                    show-overflow-tooltip
                    width="300"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.examineNames">{{ scope.row.examineNames }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="手术时间" width="150">
                    <template slot-scope="scope">
                      <div class="patientNameContent">
                        {{ formatDate(scope.row.studyDate) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="上次随访" min-width="200">
                    <template slot-scope="scope">
                      <div class="tableAnimationHeight">
                        <el-tag
                          type="info"
                          size="small"
                          style="margin-left: 7px"
                          v-show="scope.row.lastFollowStage"
                        >
                          {{ scope.row.lastFollowStage }}
                        </el-tag>
                        <el-tag
                          type="info"
                          size="small"
                          style="margin-left: 7px"
                          v-show="scope.row.lastFollowDate"
                        >
                          {{ formatDate(scope.row.lastFollowDate) }}
                        </el-tag>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="本次随访" min-width="200">
                    <template slot-scope="scope">
                      <div class="tableAnimationHeight">
                        <el-tag
                          type="info"
                          size="small"
                          style="margin-left: 7px"
                          v-show="scope.row.nextFollowStage"
                        >
                          {{ scope.row.nextFollowStage }}
                        </el-tag>
                        <el-tag
                          type="info"
                          size="small"
                          style="margin-left: 7px"
                          v-show="scope.row.nextFollowDate"
                        >
                          {{ formatDate(scope.row.nextFollowDate) }}
                        </el-tag>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @size-change="handleSizeChange4"
                  @current-change="handleCurrentChange4"
                  :current-page="userInfo_all.pagenum"
                  :page-sizes="[15, 30, 50]"
                  :page-size="userInfo_all.pagesize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="userInfo_all.total"
                ></el-pagination>
              </el-card>
            </div>
          </el-tab-pane>

          <el-tab-pane name="1">
            <span slot="label">
              <i class="el-icon-setting" style="padding-right: 5px"></i>
              随访管理
            </span>
            <div class="surgery-show">
              <div class="op-wrap">
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  v-debounce="followSearch"
                  icon="el-icon-search"
                >
                  查询
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  v-debounce="clearTable"
                  icon="el-icon-refresh-left"
                >
                  清空
                </el-button>
                <!-- <el-button
                  type="primary"
                  size="mini"
                  v-debounce="batchAllocation"
                  icon="el-icon-finished"
                >
                  发送通知
                </el-button> -->
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  v-debounce="notificationQuery"
                  icon="el-icon-postcard"
                >
                  查看通知
                </el-button>
                <el-button
                  type="primary"
                  size="mini"
                  class="commonBtn"
                  icon="el-icon-upload2"
                  @click="handleReport"
                >
                  数据上报
                </el-button>
              </div>

              <el-card class="conditions-wrap-two">
                <el-row class="item-wrap" type="flex" align="middle" justify="space-around">
                  <el-col :span="isNewCoL === false ? 6 : 10">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">库号</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 12">
                        <el-input
                          placeholder="请输入患者库号"
                          clearable
                          type="string"
                          v-model="listQuery.fileNumber"
                          @change="fetchData(1)"
                          size="mini"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">患者姓名</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 16">
                        <el-input
                          placeholder="请输入患者姓名"
                          type="string"
                          clearable
                          v-model="listQuery.patientName"
                          @change="fetchData(1)"
                          size="mini"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">病案号</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 16">
                        <el-input
                          placeholder="请输入病案号"
                          type="string"
                          clearable
                          v-model="listQuery.patientID"
                          @change="fetchData(1)"
                          size="mini"
                        ></el-input>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row class="item-wrap" type="flex" align="middle" justify="space-around">
                  <el-col :span="isNewCoL === false ? 6 : 10">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">本次计划随访时间</el-col>
                      <el-col :span="18">
                        <el-date-picker
                          v-model="nextDate"
                          type="daterange"
                          align="center"
                          size="mini"
                          unlink-panels
                          @change="handleNextDate"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"
                          format="yyyy-MM-dd"
                          :picker-options="pickerOptions"
                        ></el-date-picker>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <el-row type="flex" align="middle">
                      <el-col :span="6" class="label">随访状态</el-col>
                      <el-col :span="isNewCoL === false ? 12 : 16">
                        <el-select
                          v-model="listQuery.followStatus"
                          placeholder="请选择"
                          clearable
                          @change="fetchData(1)"
                        >
                          <el-option
                            v-for="item in followOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6"></el-col>
                </el-row>
              </el-card>
              <el-card class="surgery-show-content">
                <el-table
                  v-loading="tableLoading"
                  :data="tableData"
                  border
                  style="width: 100%"
                  :height="tableHeight + 35"
                  @selection-change="handleSelectionChange"
                >
                  <el-table-column align="center" type="index" width="30"></el-table-column>

                  <el-table-column
                    align="center"
                    prop="fileNumber"
                    label="库号"
                    show-overflow-tooltip
                    width="130"
                  ></el-table-column>
                  <el-table-column align="center" label="患者姓名" sortable width="100">
                    <template slot-scope="scope">
                      <div class="patientNameContent">
                        <el-button @click="checkPatient(scope.row)" type="text" size="small">
                          {{ scope.row.patientName }}
                        </el-button>
                        <div v-if="scope.row.isDead == '是'" class="patientIsDead">亡</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    prop="patientID"
                    label="病案号"
                    width="130"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    prop="patientSex"
                    sortable
                    label="性别"
                    width="70"
                  ></el-table-column>
                  <el-table-column
                    align="center"
                    sortable
                    label="年龄"
                    show-overflow-tooltip
                    prop="patientsAge"
                    width="70"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.ageDays">
                        {{ calculateAgeFromDays(scope.row.ageDays) }}
                      </span>
                      <span v-else>
                        {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="联系电话"
                    show-overflow-tooltip
                    width="140"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.patientPhone">{{ scope.row.patientPhone }}</span>
                      <span v-else>{{ scope.row.contactPhone }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="手术名称"
                    show-overflow-tooltip
                    width="200"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.examineNames">{{ scope.row.examineNames }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="手术时间" width="120">
                    <template slot-scope="scope">
                      <div class="patientNameContent">
                        {{ formatDate(scope.row.studyDate) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="上次随访" width="200">
                    <template slot-scope="scope">
                      <div class="tableAnimationHeight">
                        <el-tag type="info" size="small" v-show="scope.row.lastFollowDate">
                          {{ formatDate(scope.row.lastFollowDate) }}
                        </el-tag>
                        <el-tag
                          type="info"
                          size="small"
                          style="margin-left: 7px"
                          v-show="scope.row.lastFollowStage"
                        >
                          {{ scope.row.lastFollowStage }}
                        </el-tag>
                      </div>
                    </template>
                  </el-table-column>

                  <el-table-column align="center" label="本次随访" min-width="160">
                    <template slot-scope="scope">
                      <div class="patientNameContent">
                        <el-tag type="info" size="small">{{ scope.row.followTitle }}</el-tag>
                        <el-tag type="info" size="small" style="margin-left: 5px">
                          {{ formatDate(scope.row.planFollowDate) }}
                          {{ scope.row.morningOrAfternoon }}
                        </el-tag>
                        <div
                          class="patientIsOverdue"
                          v-if="
                            scope.row.overdue === true &&
                            (scope.row.followStatus !== '已完成' ||
                              scope.row.followStatus !== '已结束')
                          "
                        >
                          逾期
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" prop="followStatus" width="80" label="随访状态">
                    <template slot-scope="scope">
                      <div v-if="scope.row.followStatus == '未预约'">
                        <el-tag type="info" size="small" v-show="scope.row.followStatus">
                          {{ scope.row.followStatus }}
                        </el-tag>
                      </div>
                      <div v-if="scope.row.followStatus == '待随访'">
                        <el-tag size="small" v-show="scope.row.followStatus">
                          {{ scope.row.followStatus }}
                        </el-tag>
                      </div>
                      <div v-if="scope.row.followStatus == '已完成'">
                        <el-tag type="success" size="small" v-show="scope.row.followStatus">
                          {{ scope.row.followStatus }}
                        </el-tag>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    align="center"
                    label="通知次数"
                    width="70"
                    prop="noticeCount"
                  ></el-table-column>
                  <el-table-column
                    type="selection"
                    :selectable="handleDisable"
                    width="70"
                  ></el-table-column>

                  <el-table-column align="center" label="操作" fixed="right" width="180">
                    <template slot="header">
                      <span style="padding: 0 10px">操作</span>
                      <el-button
                        v-if="multipleSelection.length !== 0"
                        type="primary"
                        size="mini"
                        v-debounce="batchAllocation"
                        icon="el-icon-finished"
                      >
                        发送通知
                      </el-button>
                    </template>
                    <template slot-scope="scope">
                      <el-button
                        v-show="scope.row.followStatus == '未预约'"
                        size="mini"
                        type="primary"
                        class="common-button commonBtn"
                        style="margin-right: 5px"
                        icon="el-icon-finished"
                        @click="handleDispatch(scope.row)"
                      >
                        通知
                      </el-button>
                      <el-button
                        v-show="scope.row.followStatus == '未预约'"
                        size="mini"
                        type="success"
                        class="common-button commonBtn"
                        style="margin: 0"
                        icon="el-icon-finished"
                        @click="handleAppointment(scope.row)"
                      >
                        预约登记
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                  @size-change="handleSizeChangeAll"
                  @current-change="handleCurrentChangeAll"
                  :current-page="userInfo_administration.pagenum"
                  :page-sizes="[15, 30, 50]"
                  :page-size="userInfo_administration.pagesize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="userInfo_administration.total"
                ></el-pagination>
              </el-card>
            </div>
          </el-tab-pane>

          <el-tab-pane name="2">
            <span slot="label">
              <i class="el-icon-date" style="padding-right: 5px"></i>
              随访日程表
            </span>
            <div v-if="tabs_index == '2'" class="surgery-show">
              <div class="op-wrap">
                <el-col :span="4">
                  <!-- 月份选择器 -->
                  <MonthPicker :time="mouthTime" @changeMonth="changeMonth"></MonthPicker>
                </el-col>
                <el-col :span="4" class="titleNote">
                  <div class="titleNoteItem">✔️已完成</div>
                  <div class="titleNoteItem">
                    <i class="el-icon-time titleNoteItemIcon"></i>
                    待随访
                  </div>
                </el-col>
              </div>
              <div class="conditions-wrap-calendar">
                <Calendar
                  :time="time"
                  :calendarList="calendarList"
                  :tabIndex="tabs_index"
                  calendarThis="two"
                  @drawerClose="drawerClose"
                ></Calendar>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div v-if="tabs_index" style="position: absolute; right: 50px; top: 10px; font-size: 15px">
          <span v-if="tabs_index === '0'">总计: {{ this.userInfo_all.total }}条</span>
          <span v-if="tabs_index === '1'">总计: {{ this.userInfo_administration.total }}条</span>
        </div>
      </div>
      <div>
        <follow-appointment-dialog
          ref="followAppointmentDialog"
          @save="followAppointmentSave"
        ></follow-appointment-dialog>
      </div>
    </div>
    <el-dialog
      :before-close="resetForm"
      width="35%"
      :title="reportTitle"
      :visible.sync="dialogFormVisible"
    >
      <el-form ref="reportForm" label-width="680px" :model="reportForm">
        <el-form-item label="上报中心" :label-width="formLabelWidth">
          <el-select v-model="reportForm.centerName" placeholder="请选择">
            <el-option
              v-for="item in reportOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 10px">
                {{ item.url }}
              </span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 月份选择器
import monthPicker from './components/monthPicker.vue'
// 日历组件
import calendar from './components/Calendar.vue'
import IndexHeader from '../../components/IndexHeader.vue'
import followAppointmentDialog from './components/followAppointmentDialog.vue'
import { dateFormat, uniqueJsonArrByField, getAfterDate } from '../../utils'

export default {
  name: 'ResearchHome',
  components: {
    IndexHeader,
    followAppointmentDialog,
    MonthPicker: monthPicker,
    Calendar: calendar
  },
  data() {
    return {
      notificationDrawer: false,
      waitFollowDrawer: false,
      time: {
        year: new Date().getFullYear(),
        month: new Date().getMonth()
      },
      mouthTime: {
        year: new Date().getFullYear(),
        month: new Date().getMonth()
      },
      searchDay: null,
      waitFollowTitle: null,
      calendarList: {},

      multipleSelection: [],
      followOptions: [
        {
          value: '未预约',
          label: '未预约'
        },
        {
          value: '待随访',
          label: '待随访'
        },
        {
          value: '已完成',
          label: '已完成'
        },
        {
          value: '逾期',
          label: '逾期'
        }
      ],
      // 导入日期选择
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.setHours(0, 0, 0, 0))
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
              end.setTime(end.setHours(0, 0, 0, 0))
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      listQuery: {
        patientID: null,
        patientName: null,
        fileNumber: null,
        notice: null,
        startTime: null,
        endTime: null,
        followStatus: null,
        size: 50,
        current: 1
      },
      nextDate: null,
      noticeDate: null,
      operationDate: null,
      tableLoading: false,
      tabs_index: '0',
      tableHeight: window.innerHeight - 326, //表格动态高度
      screenHeight: window.innerHeight, //内容区域高度
      tableData: [],
      // 管理数据
      userInfo_administration: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      notDatedTableData: [],
      // 未预约
      userInfo_notDated: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      drawerFollowTableData: [],
      // 日历表单
      userInfo_drawerFollow: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      tableAllData: [],
      // 所有数据
      userInfo_all: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      reportType: null,
      reportForm: {},
      formLabelWidth: '120px',
      dialogFormVisible: false,
      reportOptions: [],
      reportTitle: null,
      isNewCoL: false,
      moduleCode: this.$route.query.moduleCode
    }
  },
  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 330
    },
    tabs_index: {
      handler(val) {
        if (val && val == '2') {
          this.mouthTime = {
            year: new Date().getFullYear(),
            month: new Date().getMonth()
          }
          this.time = {
            year: new Date().getFullYear(),
            month: new Date().getMonth()
          }
          this.getCalendar()
        }
      },
      immediate: true
    }
  },
  created() {
    this.getData()
    this.fetchData(1)
  },
  mounted() {
    if (window.innerHeight < 800) {
      this.isNewCoL = true
    }
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
  },

  methods: {
    dateFormat,
    uniqueJsonArrByField,
    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()

        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()

        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return ''
      }
    },

    tableRowClassName({ row }) {
      if (
        row.overdue === true &&
        (row.followStatus !== '已完成' || row.followStatus !== '已结束')
      ) {
        return 'warning-row'
      } else {
        return ''
      }
    },
    getData() {
      let nextDateStart = new Date()
      let nextDateEnd = new Date()
      if (this.notificationDrawer === true) {
        this.noticeDate = [
          nextDateEnd.getFullYear() +
            '-' +
            (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
            (nextDateEnd.getMonth() + 1) +
            '-' +
            (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
            nextDateStart.getDate(),
          getAfterDate(7)
        ]
        this.listQuery.next = null
        this.listQuery.notice = this.noticeDate.join(',')
      } else {
        if (this.tabs_index === '1') {
          this.nextDate = [
            nextDateEnd.getFullYear() +
              '-' +
              (nextDateEnd.getMonth() + 1 < 10 ? '0' : '') +
              (nextDateEnd.getMonth() + 1) +
              '-' +
              (nextDateStart.getDate() + 1 < 10 ? '0' : '') +
              nextDateStart.getDate(),
            getAfterDate(7)
          ]
          this.listQuery.next = this.nextDate.join(',')
        } else {
          this.nextDate = null
        }
      }
    },
    formatTime(v) {
      if (v === null) {
        return ''
      }
      let date = new Date(v)
      return date.getHours() < 12 ? '上午' : '下午'
    },
    formatDate(v) {
      if (!v || typeof v !== 'string') {
        return ''
      }
      const dateRegex = /^\d{4}-\d{2}-\d{2}/
      if (!dateRegex.test(v)) {
        return ''
      }

      return v.substring(0, 10)
    },
    formatHoursDate(v, index) {
      if (!v || typeof v !== 'string') {
        return ''
      }
      const dateRegex = /^\d{4}-\d{2}-\d{2}/
      if (!dateRegex.test(v)) {
        return ''
      }

      return v.substring(0, index)
    },

    fetchData(page) {
      let url = ''
      if (this.tabs_index === '1') {
        if (this.notificationDrawer === true) {
          if (page) {
            this.userInfo_notDated.pagenum = page
          }
          url = `/v1/webconsole/follow/list/follows/${this.moduleCode}?current=${this.userInfo_notDated.pagenum}&size=${this.userInfo_notDated.pagesize}`
        } else {
          if (page) {
            this.userInfo_administration.pagenum = page
          }
          url = `/v1/webconsole/follow/list/follows/${this.moduleCode}?current=${this.userInfo_administration.pagenum}&size=${this.userInfo_administration.pagesize}`
        }
      } else if (this.tabs_index === '0') {
        if (page) {
          this.userInfo_all.pagenum = page
        }
        url = `/v1/webconsole/follow/list/tofill/${this.moduleCode}?current=${this.userInfo_all.pagenum}&size=${this.userInfo_all.pagesize}`
      }
      let searchOption = {
        patientName: this.listQuery.patientName, // 患者姓名
        patientID: this.listQuery.patientID, // 病案号
        fileNumber: this.listQuery.fileNumber, // 库号
        nextDate: this.listQuery.next, //下次随访时间
        startTime: this.listQuery.startTime, //下次随访时间
        endTime: this.listQuery.endTime, //下次随访时间
        noticeDate: this.listQuery.notice, // 通知时间
        followStatus: this.listQuery.followStatus, // 随访状态
        moduleCode: this.tabs_index === '1' ? null : this.moduleCode
      }

      for (let i in this.listQuery) {
        if (this.listQuery[i] === '') {
          this.listQuery[i] = null
        }
      }

      this.tableLoading = true
      this.$api
        .get(url, searchOption)
        .then((res) => {
          if (this.tabs_index === '1') {
            if (this.notificationDrawer === true) {
              this.notDatedTableData = res.data.data.records
              this.userInfo_notDated.total = res.data.data.total
            } else {
              this.tableData = res.data.data.records
              this.userInfo_administration.total = res.data.data.total
            }
          } else if (this.tabs_index === '0') {
            this.tableAllData = res.data.data.records
            this.userInfo_all.total = res.data.data.total
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 修改月份
    changeMonth(time) {
      this.time = time
      this.getCalendar()
    },
    drawerClose(val, index) {
      this.nextDate = null
      if (index) {
        if (val) {
          this.searchDay = val
        } else {
          this.searchDay = null
        }
        this.waitFollowTitle = this.searchDay
        this.waitFollowDrawer = true
        this.getCalendarList()
      }
    },
    getCalendarList() {
      let searchOption = {
        patientName: this.listQuery.patientName,
        fileNumber: this.listQuery.fileNumber,
        date: this.searchDay
        // moduleCode: this.moduleCode
      }

      for (let i in this.listQuery) {
        if (this.listQuery[i] === '') {
          this.listQuery[i] = null
        }
      }
      this.tableLoading = true
      this.$api
        .get(`/v1/webconsole/follow/day/${this.moduleCode}`, searchOption)
        .then((res) => {
          if (this.tabs_index === '2' && res.data && res.data.data) {
            this.drawerFollowTableData = res.data.data
            if (res.data.data.length > 0) {
              this.userInfo_drawerFollow.total = res.data.data.length
            }
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getCalendar() {
      let mouth = this.time.month + 1

      this.$api
        .get(
          `/v1/webconsole/follow/calendar/${this.moduleCode}?year=${this.time.year}&month=${mouth}`
        )
        .then((res) => {
          this.calendarList = res.data.data
        })
    },

    handleSelectionChange(val) {
      this.multipleSelection = []
      let list = val
      for (let i = 0; i < list.length; i++) {
        const element = list[i]
        this.multipleSelection.push(element)
      }
    },

    followAppoint() {
      let data = []
      this.multipleSelection.forEach((item) => {
        if (item.followId) {
          data.push(item.followId)
        }
      })
      this.$api.post(`/v1/webconsole/follow/sendScheduleMessage`, data).then((res) => {
        this.fetchData(1)
        this.multipleSelection = []
        this.$message({
          message: '成功发送' + res.data.data + '条信息',
          type: 'success'
        })
      })
    },
    followAppointmentSave(val) {
      let formData = new FormData()
      formData.append('date', val.confirmOrderDate)
      if (val.memo) {
        formData.append('memo', val.memo)
      }

      this.$api.post(`/v1/webconsole/follow/date/${val.followId}`, formData).then((res) => {
        this.fetchData(1)
        this.$message({
          message: res.data.msg,
          type: 'success'
        })
      })
    },
    waitFollowHandleClose() {
      this.waitFollowDrawer = false
      this.clearTable('close')
    },
    notificationHandleClose() {
      this.notificationDrawer = false
      this.clearTable('close')
      this.getData()
      setTimeout(() => {
        this.fetchData(1)
      }, 500)
    },
    notificationQuery() {
      this.notificationDrawer = true
      this.getData()
      setTimeout(() => {
        this.fetchData(1)
      }, 500)
    },
    batchAllocation() {
      if (this.multipleSelection.length > 0) {
        if (this.tabs_index === '1') {
          this.followAppoint()
        } else {
          let data = []
          for (let i = 0; i < this.multipleSelection.length; i++) {
            const element = this.multipleSelection[i]
            data.push(element.uuid)
          }
          this.axios
            .post(`/v1/webconsole/follow/export/patient`, data, {
              responseType: 'blob'
            })
            .then((res) => {
              if (res.status === 200) {
                const { data, headers } = res
                let filename = ''
                if (headers['content-disposition']) {
                  filename = headers['content-disposition']
                    .split(';')[1]
                    .split('=')[1]
                    .split("8''")[1]
                } else {
                  filename = '患者列表批量导出.xlsx'
                }
                // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
                const blob = new Blob([data], {
                  type: headers['content-type']
                })
                let dom = document.createElement('a')
                let url = window.URL.createObjectURL(blob)
                dom.href = url
                dom.download = decodeURI(filename)
                dom.style.display = 'none'
                document.body.appendChild(dom)
                dom.click()
                dom.parentNode.removeChild(dom)
                window.URL.revokeObjectURL(url)
              } else {
                this.$message({
                  message: '导出失败',
                  type: 'warning'
                })
              }
            })
        }
      } else {
        this.$message({
          message: '请选择一个患者',
          type: 'warning'
        })
      }
    },
    exportList() {
      if (this.multipleSelection.length > 0) {
        let data = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i]
          data.push(element.uuid)
        }

        this.axios
          .post(`/v1/webconsole/follow/export/patient`, data, {
            responseType: 'blob'
          })
          .then((res) => {
            if (!res.headers['content-disposition']) {
              return this.$message.error('该模块未配置相关随访菜单')
            }
            const { data, headers } = res
            let filename = ''
            if (headers['content-disposition']) {
              filename = headers['content-disposition'].split(';')[1].split('=')[1].split("8''")[1]
            } else {
              filename = '随访信息批量导出.xlsx'
            }
            // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
            const blob = new Blob([data], { type: headers['content-type'] })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          })
      } else {
        this.$message({
          message: '请选择一个患者',
          type: 'warning'
        })
      }
    },
    handleAppointment(item) {
      this.$refs.followAppointmentDialog.Show(item)
    },
    handleDispatch(item) {
      this.multipleSelection = []
      this.multipleSelection.push(item)
      this.followAppoint()
    },

    // 处理下次随访日期选择情况
    handleNextDate() {
      if (!this.nextDate) {
        this.listQuery.next = null
      } else {
        this.listQuery.next = this.nextDate.join(',')
      }
      this.fetchData(1)
    },
    // 处理通知时间选择情况
    handleNoticeDate() {
      if (!this.noticeDate) {
        this.listQuery.notice = null
      } else {
        this.listQuery.notice = this.noticeDate.join(',')
      }
      this.fetchData(1)
    },
    // 处理通知时间选择情况
    handleOperationDate() {
      if (!this.operationDate) {
        this.listQuery.startTime = null
        this.listQuery.endTime = null
      } else {
        this.listQuery.startTime = this.operationDate[0]
        this.listQuery.endTime = this.operationDate[1]
      }
      this.fetchData(1)
    },
    // 清空
    clearTable(info) {
      for (let i in this.listQuery) {
        if (this.listQuery[i] !== 'size' && this.listQuery[i] !== 'current') {
          this.listQuery[i] = null
        }
      }
      if (this.notificationDrawer === true) {
        this.noticeDate = null
        this.nextDate = null
      } else {
        this.nextDate = null
        this.operationDate = null
      }

      if (info != 'close') {
        if (this.tabs_index === '2') {
          this.getCalendarList()
        } else {
          this.fetchData(1)
        }
      }
    },
    // 查询
    followSearch() {
      this.fetchData(1)
    },
    handleDisable(row) {
      if (this.tabs_index === '1') {
        if (row.followStatus != '未预约') {
          return false
        } else {
          return true
        }
      } else if (this.tabs_index === '0') {
        if (row.followStatus != '未预约') {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    cencelFollow(val) {
      this.$api.get(`/v1/webconsole/follow/cancel/${val}`).then((res) => {
        if (res.data.status === 200) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.getCalendarList()
          this.getCalendar()
        }
      })
    },
    checkPatientFollow(row) {
      row.detailType = 'follow'
      localStorage.setItem('patientInfo', JSON.stringify(row))
      this.$api
        .get(`/v1/webconsole/follow/menu/${row.operationId}/${this.$route.query.moduleCode}`)
        .then(({ data: res }) => {
          res.data.forEach((item) => {
            if (item.menuTitle === '随访记录') {
              for (let i = 0; i < item.subMenus.length; i++) {
                const element = item.subMenus[i]
                if (element.menuTitle === row.followTitle) {
                  const { href } = this.$router.resolve({
                    path: `/follow/follow?stage=${item.subMenus[i].menuTitle}${
                      '&moduleCode=' + this.moduleCode
                    }${row.moduleId ? '&moduleId=' + row.moduleId : ''}${
                      row.operationId
                        ? '&operationId=' + row.operationId
                        : '' || row.uuid
                        ? '&operationId=' + row.uuid
                        : ''
                    }${
                      row.followId
                        ? '&followId=' + row.followId
                        : '' || row.nextFollowId
                        ? '&followId=' + row.nextFollowId
                        : ''
                    }`
                  })
                  window.open(href, '_blank')
                  break
                }
              }
            }
          })
        })
    },
    checkPatient(row) {
      row.detailType = 'follow'
      const { href } = this.$router.resolve({
        path: `/follow/baseLine?${'moduleCode=' + this.moduleCode}${
          row.moduleId ? '&moduleId=' + row.moduleId : ''
        }${
          row.operationId
            ? '&operationId=' + row.operationId
            : '' || row.uuid
            ? '&operationId=' + row.uuid
            : ''
        }${
          row.followId
            ? '&followId=' + row.followId
            : '' || row.nextFollowId
            ? '&followId=' + row.nextFollowId
            : ''
        }`
      })
      localStorage.setItem('patientInfo', JSON.stringify(row))
      window.open(href, '_blank')
    },
    // 页码切换
    handleClick(tab) {
      this.tabs_index = tab.index
      this.multipleSelection = []
      if (this.tabs_index != '2') {
        this.clearTable('close')
        this.getData()
        setTimeout(() => {
          this.fetchData(1)
        })
      }
    },
    // 监听 pagesize 改变的事件
    handleSizeChangeAll(newSize) {
      this.userInfo_administration.pagesize = newSize
      this.fetchData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChangeAll(newPage) {
      this.userInfo_administration.pagenum = newPage
      this.fetchData()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange1(newSize) {
      this.userInfo_notDated.pagesize = newSize
      this.fetchData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange1(newPage) {
      this.userInfo_notDated.pagenum = newPage
      this.fetchData()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange4(newSize) {
      this.userInfo_all.pagesize = newSize
      this.fetchData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange4(newPage) {
      this.userInfo_all.pagenum = newPage
      this.fetchData()
    },
    // 监听 pagesize 改变的事件
    handleSizeChange5(newSize) {
      this.userInfo_drawerFollow.pagesize = newSize
      this.fetchData()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange5(newPage) {
      this.userInfo_drawerFollow.pagenum = newPage
      this.fetchData()
    },
    removeRepeat(data) {
      let arr = []
      for (let i = 0; i < data.length; i++) {
        const element = data[i]
        arr.push({
          label: element.centerName,
          value: element.uuid,
          url: element.url
        })
      }
      return arr
    },
    // 上报
    handleReport() {
      this.reportTitle = '数据上报'
      this.$api.get(`/v1/webconsole/remotecenter/centers`).then((res) => {
        if (res.data.data && res.data.status === 200) {
          this.reportOptions = this.removeRepeat(res.data.data)
          this.dialogFormVisible = true
        }
      })
    },
    submitForm() {
      if (this.reportForm.centerName && this.tabs_index === '1') {
        let data = []
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i]
          data.push(element.followId)
        }
        this.$api
          .post(`/v1/webconsole/follow/report/${this.reportForm.centerName}`, data)
          .then((res) => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg)
              this.resetForm()
              this.fetchData()
            }
          })
      } else if (!this.reportForm.centerName) {
        this.$message({
          message: '请选择上报中心',
          type: 'warning'
        })
      }
    },
    resetForm() {
      this.dialogFormVisible = false
      this.reportForm = {}
    }
  }
}
</script>

<style lang="less" scoped>
.el-table /deep/ .warning-row {
  background: rgba(254, 67, 101);
  // background: #ff2d51;
}

.calendar-con {
  margin-top: 20px;
}
.research-home {
  .research-home-content {
    /deep/.el-drawer {
      background: #eff0f7;
    }
    .research-home-title {
      .drawerRight {
        float: right;
      }
      span {
        padding: 0 10px;
      }
    }
    .drawerCalendarNote {
      display: flex;
      align-items: center;
      .drawerCalendarNoteItem {
        display: flex;
        padding: 5px;
        font-size: 15px;
        font-weight: 600;
        .drawerCalendarNoteItemIcon {
          font-size: 20px;
          padding: 0 3px;
        }
      }
    }
    .surgery-status {
      position: relative;

      .titleNote {
        display: flex;
        align-items: center;
        .titleNoteItem {
          display: flex;
          padding: 5px;
          font-size: 15px;
          font-weight: 600;
          .titleNoteItemIcon {
            color: #00ad1d;
            font-size: 20px;
            padding: 0 3px;
          }
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
        .el-tabs__nav-wrap {
          height: calc(100vh - 62px);
          background: #fff;
          .el-tabs__item {
            color: #000;
            width: 180px;
            height: 56px;
            line-height: 56px;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
          }
          .el-tabs__item:hover,
          .el-tabs__item:focus {
            outline: none;
            background-color: #eff6ff;
            color: #2d507e;
          }
          .el-tabs__item.is-active {
            //标签点击时，背景色变为白色，字体变为黑色，
            background-color: #eff6ff;
            color: #2d507e;
          }
        }
      }
      /deep/ .el-table {
        border: 0.0625rem solid #ebeef5;
      }
    }
    /deep/ .el-drawer__header {
      span {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
.op-wrap {
  padding: 0 20px;
  height: 45px;
  display: flex;
  align-items: center;
  background: #f2f2f2;
  box-shadow: 0 2px 2px #dedede;
  .el-button {
    font-size: 14px;
  }
}

.surgery-show-drawer {
  display: flex;
  flex-direction: column;
  background: #eff0f7;
}

.surgery-show {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  background: #eff0f7;
}

.surgery-show-content {
  margin-top: 10px;
  margin-left: 10px;
  /deep/.el-card__body {
    padding: 0;
  }
  ::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
    color: #606266;
    content: '全选';
    font-size: 12px;
    margin-left: 2px;
    font-weight: bold;
  }
  .patientNameContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .patientIsOverdue {
    width: 30px;
    line-height: 15px;
    border-radius: 10px;
    border: 2px solid red;
    color: red;
  }
  .patientIsDead {
    width: 20px;
    height: 20px;
    line-height: 15px;
    border-radius: 10px;
    border: 2px solid red;
    color: red;
  }
  .el-pagination {
    text-align: center;
  }
  /deep/.el-table td.el-table__cell div {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
  }
  .tableAnimationHeight {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
  }
}
.conditions-wrap {
  padding-top: 20px;
  min-height: 160px;
  margin-top: 10px;
  margin-left: 10px;
  /deep/.el-card__body {
    padding: 0;
  }
  .item-wrap {
    height: 45px;
    .label {
      text-align: center;
    }
  }
}
.conditions-wrap-calendar ::-webkit-scrollbar {
  width: 12px;
}
.conditions-wrap-calendar ::-webkit-scrollbar-track {
  border-radius: 10px;
}
.conditions-wrap-calendar ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.conditions-wrap-calendar ::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.conditions-wrap-calendar {
  flex: 1;
  overflow: auto;
  padding-top: 10px;
  margin-top: 10px;
  margin-left: 10px;
  background: #fff;
  border-top-left-radius: 8px;
  /deep/.el-card__body {
    padding: 0;
  }
  .item-wrap {
    height: 45px;
    .label {
      text-align: center;
    }
  }
}
.conditions-wrap-calendar::-webkit-scrollbar {
  width: 12px;
}
.conditions-wrap-calendar::-webkit-scrollbar-track {
  border-radius: 10px;
}
.conditions-wrap-calendar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.conditions-wrap-calendar::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}

.conditions-wrap-two {
  padding-top: 10px;
  height: 112px;
  margin-top: 10px;
  margin-left: 10px;
  /deep/.el-card__body {
    padding: 0;
  }
  .item-wrap {
    height: 45px;
    .label {
      text-align: center;
    }
  }
}
</style>
<style lang="less">
.add-form {
  .el-dialog {
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }

    .el-dialog__body {
      height: 600px;
      overflow-y: auto;
      padding: 0;

      .dialog-btn-wrap {
        position: fixed;
        height: 50px;
        width: 50%;
        background-color: #f2f2f2;
        z-index: 100;
        display: flex;
        align-items: center;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }
    .form-wrap {
      margin-top: 80px;
      padding: 0 20px;
      height: 500px;
      overflow-y: auto;

      .adviceTable {
        display: flex;
        margin-bottom: 15px;
        .adviceTableLabel {
          white-space: nowrap;
          .adviceTableHeader {
            margin: 0 -1px -1px 0;
            background-color: #00a5ff;
            border: 1px solid #4c6fe2;
            height: 30px;
          }
          .adviceTableLabelItem {
            margin: 0 -1px -1px 0;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #aae1fe;
            border: 1px solid #4c6fe2;
          }
        }
        .adviceTableContent {
          width: 100%;
          display: flex;
          .adviceTableHeader {
            margin: 0 -1px -1px 0;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background-color: #00a5ff;
            border: 1px solid #4c6fe2;
          }
          .adviceTableContentItem {
            margin: 0 -1px -1px 0;
            width: 120px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #00a5ff;
            cursor: pointer;
          }
          .adviceTableContentItem:hover {
            z-index: 1;
            border: 1px solid #000;
            background: #aae1fe;
          }
        }
      }
      .el-divider__text {
        background-color: #f8f8f8;
      }
      .el-row {
        margin-bottom: 15px;
      }
      .el-input {
        width: 250px;
      }
      .el-input__inner {
        border-color: #dcdfe6;
        height: 35px;
      }
      .next {
        margin-bottom: 15px;
      }
      .el-tabs__header {
        position: fixed;
        z-index: 100;
        background: #fff;
      }
      .fixed-btn {
        margin-top: 40px;
        .el-button {
          position: fixed;
          z-index: 500;
        }
      }
      .el-tabs__content {
        .container {
          margin-top: 80px;
        }
      }
    }
  }
}
</style>
